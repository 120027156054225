import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('h3',[_c(VIcon,[_vm._v("mdi mdi-cog-outline")]),_vm._v(" Configuration")],1)])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('ContentHeader',{attrs:{"description":'Theme Color'}}),_c(VRadioGroup,{attrs:{"row":"","color":"var(--color__main)"},on:{"change":function($event){return _vm.changeTheme()}},model:{value:(_vm.userLoggedGetters.themeColor),callback:function ($$v) {_vm.$set(_vm.userLoggedGetters, "themeColor", $$v)},expression:"userLoggedGetters.themeColor"}},[_c(VRadio,{attrs:{"label":"Dark","value":1}}),_c(VRadio,{attrs:{"label":"Light","value":2}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }